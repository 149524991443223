import SEO from '@/components/Seo/Seo';
import ErrorContainer from '@/containers/Error/ErrorPage';
import HomeLayout from '@/layouts/home.layout';

const ErrorPage = () => (
  <HomeLayout>
    <SEO title="404" noindex />
    <ErrorContainer />
  </HomeLayout>
);

ErrorPage.public = true;

export default ErrorPage;
